<template>
<el-dialog v-model="visible" :title="title" custom-class="lxcg-version-update-dialog" :close-on-click-modal="false" :before-close="close">
  <el-form :model="form" ref="ruleFormRef" :rules="rules" v-loading="loading">
    <el-form-item label="版本名称：" prop="txtHardwareVersionCode">
      <el-input v-model="form.txtHardwareVersionCode" placeholder="请填写版本名称，最多20个字符，不可输入特殊字符" maxlength="20" />
    </el-form-item>
    <el-form-item label="关联曲线组：" prop="lxcgCurveGroupId">
      <el-select v-model="form.lxcgCurveGroupId" clearable placeholder="请选择关联曲线组" size="small">
        <el-option v-for="(item, index) in options.curveOption" :key="index" :label="item.txtLxcgCurveGroupName" :value="item.lxcgCurveGroupId" />
      </el-select>
    </el-form-item>
    <el-form-item label="版本详情：" prop="txtHardwareVersionDesc">
      <el-input v-model="form.txtHardwareVersionDesc" maxlength="500" :autosize="{minRows: 10, maxRows: 20}" show-word-limit type="textarea" placeholder="请填写当前版本内元器件组成说明，最多500个字符" />
    </el-form-item>
  </el-form>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="close('cancel')">取 消</el-button>
      <el-button type="primary" @click="close('confirm')">确 认</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 胶体金版本管理 - 新增/编辑 
import { useStore } from 'vuex';
import { ElMessage, FormInstance, } from 'element-plus';
import { reactive, toRefs, ref, computed, onMounted } from "vue";
import { getCurveVersionList, addLxcgVersion, getLxcgVersionDetail, updateLxcgVersion } from "api/apis.js";
export default {
  props: {
    updateData: {
      type: Object,
      default: function () {
        return { type: "add" };
      }
    },
  },
  setup(prop, ctx) {
    const store = useStore();

    // 表单校验 - 版本名称 仅可输入英文、数字及. 不可输入特殊字符
    const versionNamePattern = (rule, value, callback) => {
      let result = value.replace(/\s/g, "");
      // 非空校验
      if ((result || "") == "") {
        callback("版本名称不可为空");
      } else {
        // 格式校验
        let reg = /^[a-zA-Z0-9.]+$/;
        if (reg.test(value.trim())) {
          callback();
        } else {
          callback("仅可输入英文、数字及 “.” ，不可输入特殊字符");
        }
        console.log(reg.test(value.trim()))
        callback();
      }
    };

    const state = reactive({
      ruleFormRef: ref(FormInstance),
      visible: true,
      loading: false,
      title: computed(() => prop.updateData.type == "edit" ? "编辑版本 - " + prop.updateData.txtHardwareVersionCode : "新增版本"),
      form: {
        txtHardwareVersionCode: "",
        lxcgCurveGroupId: "",
        txtHardwareVersionDesc: ""
      },
      options: {
        curveOption: []
      },
      rules: {
        txtHardwareVersionCode: [
          { required: true, message: "版本名称不可为空", trigger: "blur" },
          { required: true, validator: versionNamePattern, trigger: "blur" }
        ],
        lxcgCurveGroupId: [
          { required: true, message: "关联曲线组不可为空", trigger: "blur" }
        ],
        txtHardwareVersionDesc: [
          { required: true, message: "版本详情不可为空", trigger: "blur" }
        ]
      }
    });

    onMounted(() => {
      getCurveOption();
      // 编辑情况 获取详情
      if (prop.updateData.type == "edit") {
        getDetail();
      }
    })

    // 获取 曲线组 option
    const getCurveOption = () => {
      getCurveVersionList().then(response => {
        if (response.code == 200) {
          state.options.curveOption = response.data;
        } else {
          ElMessage.error(response.message);
          state.options.curveOption = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取 批次详情
    const getDetail = () => {
      state.loading = true;
      getLxcgVersionDetail({ hardwareVersionId: prop.updateData.hardwareVersionId }).then(response => {
        if (response.code == 200) {
          state.form = response.data;
        } else {
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    const close = type => {
      if (type == "confirm") {
        state.ruleFormRef.validate(valid => {
          if (valid) {
            // 校验成功
            state.loading = true;
            let param = {
              lxcgCurveGroupId: state.form.lxcgCurveGroupId,
              txtHardwareVersionCode: state.form.txtHardwareVersionCode.trim(),
              txtHardwareVersionDesc: state.form.txtHardwareVersionDesc
            };
            // 判断是新增还是编辑
            if (prop.updateData.type == "add") {
              // 是新增
              param.txtCreator = store.getters.userInfo ? store.getters.userInfo.name : "系统用户";
              addLxcgVersion(param).then(response => {
                if (response.code == 200) {
                  ElMessage.success("新增成功");
                  ctx.emit("close", true);
                } else {
                  ElMessage.error(response.message);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              })
            } else {
              // 是编辑
              param.txtUpdater = store.getters.userInfo ? store.getters.userInfo.name : "系统用户";
              param.hardwareVersionId = state.form.hardwareVersionId;
              updateLxcgVersion(param).then(response => {
                if (response.code == 200) {
                  ElMessage.success("编辑成功");
                  ctx.emit("close", true);
                } else {
                  ElMessage.error(response.message);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              })
            }
          }
        });
      } else {
        ctx.emit("close", false);
      }
    };

    return {
      ...toRefs(state),
      close,
      getDetail,
      getCurveOption
    }
  }
}
</script>

<style lang="scss">
.lxcg-version-update-dialog {
  width: 40%;

  .el-form {
    .el-form-item {
      margin-left: 14px;

      .el-form-item__label {
        width: 110px;
        text-align: left;
      }

      .el-form-item__content {
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
