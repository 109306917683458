<template>
<div class="lxcg-version-ma-in-operate" id="page">
  <el-row class="row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item prop="txtHardwareVersionCode">
          <el-input v-model="form.txtHardwareVersionCode" clearable placeholder="版本名称">
            <template #prepend>
              <i class="iconfont icon-sousuo" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="dateRange">
          <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="曲线组：" prop="lxcgCurveGroupId">
          <el-select v-model="form.lxcgCurveGroupId" clearable placeholder="请选择" size="small">
            <el-option v-for="(item, index) in options.curveOption" :key="index" :label="item.txtLxcgCurveGroupName" :value="item.lxcgCurveGroupId" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">搜索</el-button>
          <el-button type="info" @click="reset()">重置</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row>
      <el-button type="primary" @click="dialog.updateShow=true">新增版本</el-button>
    </el-row>
  </el-row>
  <el-table :data="table.tableData" v-loading="table.tabelLoading">
    <el-table-column prop="txtHardwareVersionCode" label="版本名称" :show-overflow-tooltip="true" align="center">
      <template #default="scope">{{scope.row.txtHardwareVersionCode||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txtHardwareVersionDesc" label="版本详情" align="center">
      <template #default="scope">
        <template v-if="scope.row.txtHardwareVersionDesc.length>40">
          <el-tooltip class="box-item" effect="dark" :content="scope.row.txtHardwareVersionDesc.slice(0, 80)+'...'" placement="top">
            {{ scope.row.txtHardwareVersionDesc.slice(0, 40) + "..." }}
          </el-tooltip>
        </template>
        <template v-else>{{ scope.row.txtHardwareVersionDesc||"- -" }}</template>
      </template>
    </el-table-column>
    <el-table-column prop="txtLxcgCurveGroupName" label="关联曲线组" align="center">
      <template #default="scope">{{scope.row.txtLxcgCurveGroupName||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="dtCreate" label="创建时间" align="center">
      <template #default="scope">{{scope.row.dtCreate||"- -"}}</template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option" width="150">
      <template #default="scope">
        <div class="table-icon" @click="handleEdit(scope.row)">
          <el-icon>
            <EditPen />
          </el-icon> 编辑
        </div>
        <div class="table-icon" @click="handleDelete(scope.row)">
          <el-icon>
            <Delete />
          </el-icon> 删除
        </div>
      </template>
    </el-table-column>
  </el-table>
  <pagination :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <update-version v-if="dialog.updateShow" :updateData="dialog.updateData" @close="(e) => {dialog.updateData={type:'add'};dialog.updateShow=false;if(e){getList()}}" />
</div>
</template>

  
<script>
// 运营管理 - 胶体金版本管理
import { useStore } from 'vuex';
import { Pagination } from "components";
import { onMounted, reactive, toRefs } from 'vue';
import UpdateVersion from "./comp/version/updateVersion.vue";
import { ElMessage, ElMessageBox, } from "element-plus";
import { EditPen, Delete } from "@element-plus/icons-vue";
import { getCurveVersionList, getLxcgVersionPage, deleteLxcgVersion } from "api/apis.js";
export default {
  components: {
    Pagination,
    UpdateVersion,
    EditPen,
    Delete,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      form: {
        txtHardwareVersionCode: "",
        lxcgCurveGroupId: "",
        dateRange: [],
        limit: 10,
        page: 1
      },
      options: {
        curveOption: []
      },
      table: {
        tableData: [],
        total: 0,
        tabelLoading: false
      },
      dialog: {
        updateShow: false,
        updateData: { type: "add" }
      }
    });

    onMounted(() => {
      getCurveOption();
      getList();
    })

    // 获取 曲线组 option
    const getCurveOption = () => {
      getCurveVersionList().then(response => {
        if (response.code == 200) {
          state.options.curveOption = response.data;
          state.options.curveOption.unshift({ lxcgCurveGroupId: "", txtLxcgCurveGroupName: "全部" });
        } else {
          ElMessage.error(response.message);
          state.options.curveOption = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 编辑版本
    const handleEdit = row => {
      row.type = "edit";
      state.dialog.updateData = row;
      state.dialog.updateShow = true;
    };

    // 删除版本
    const handleDelete = row => {
        // 二次确认，调用删除接口
        ElMessageBox.confirm(
          `请确认是否删除当前【${row.txtHardwareVersionCode}】版本？`,
          '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确定'
          }
        ).then(() => {
          state.table.tabelLoading = true;
          deleteLxcgVersion({
            hardwareVersionId: row.hardwareVersionId,
            txtUpdater: store.getters.userInfo ? store.getters.userInfo.name : "系统用户"
          }).then(res => {
            if (res.code == 200) {
              ElMessage.success("删除成功");
              getList();
            } else {
              ElMessage.error(res.message);
            }
          }).catch(e => {
            console.error(e);
          }).finally(() => {
            state.table.tabelLoading = false;
          })
        }).catch(() => {
          // 取消删除
        })
    };

    // 获取列表数据
    const getList = () => {
      state.table.tabelLoading = true;
      let param = {
        pageNum: state.form.page,
        pageSize: state.form.limit,
        query: {
          lxcgCurveGroupId: state.form.lxcgCurveGroupId,
          txtHardwareVersionCode: state.form.txtHardwareVersionCode,
          dtFrom: (state.form.dateRange || []).length == 0 ? "" : state.form.dateRange[0],
          dtTo: (state.form.dateRange || []).length == 0 ? "" : state.form.dateRange[1]
        }
      }
      getLxcgVersionPage(param).then(response => {
        if (response.code == 200) {
          state.table.tableData = response.data.list;
          state.table.total = response.data.total;
        } else {
          state.table.tableData = response.data.list;
          state.table.total = response.data.total;
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tabelLoading = false;
      })
    };

    // 重置表单
    const reset = () => {
      state.form = {
        txtHardwareVersionCode: "",
        lxcgCurveGroupId: "",
        dateRange: [],
        limit: 10,
        page: 1
      }
      getList();
    };

    return {
      ...toRefs(state),
      getList,
      reset,
      handleEdit,
      handleDelete,
      getCurveOption
    }
  }
}
</script>

  
<style lang="scss" scoped>
.lxcg-version-ma-in-operate {
  .el-table {
    height: calc(100% - 95px);
  }
}
</style>
